import { Box, Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import helpers from "../../services/helpers";

const FallbackComponent = ({ src, alt, sx, data, images }) => {
  if (images && images[0]) {
    const path = process.env.REACT_APP_TMDB_BASE_URL + images[0]?.poster;
    return (
      <Box component="img" className="d-block" sx={sx} alt={alt} src={path} />
    );
  } else {
    return (
      <Box
        sx={sx}
        data={src}
        style={{
          backgroundColor: "#3f3d3d",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p style={{ color: "#e94560", fontWeight: "bold", width: "100%" }}>
          {data?.name || alt}
        </p>
        <p style={{ color: "#e94560", width: "100%", margin: 0 }}>
          {data?.language} | {data?.year}
        </p>
      </Box>
    );
  }
};

const ImageWithFallback = ({ src, alt, sx, data, images = [] }) => {
  const [isImageAvailable, setIsImageAvailable] = useState(null);

  useEffect(() => {
    // Function to check if image exists
    const checkImageUrl = async () => {
      try {
        const res = await helpers.checkIfFileExists(
          process.env.REACT_APP_S3_TEMP_BUCKET_NAME,
          src
        );

        setIsImageAvailable(res);
      } catch (error) {
        console.error("Error checking image:", error);
        setIsImageAvailable(false);
      }
    };

    checkImageUrl();
  }, [src]);

  if (isImageAvailable === null) {
    return <Skeleton animation="wave" variant="rectangular" sx={sx} />;
  }

  return (
    <div>
      {isImageAvailable ? (
        <Box component="img" className="d-block" sx={sx} alt={alt} src={src} />
      ) : (
        <FallbackComponent
          src={src}
          alt={alt}
          sx={sx}
          data={data}
          images={images}
        /> // If the image is not available, render the fallback component
      )}
    </div>
  );
};

export default ImageWithFallback;
