import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  // Pagination,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MyHelmet from "../common/MyHelmet";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvancedSearchData,
  getAutoSearchData,
  getSearchData,
  handleFilters,
  handleSearchBarFilters,
  resetPartnerData,
} from "../../slices/frenziPartner";
// import MediumTile from "../common/MediumTile";

import "./_partner-search.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
// import SideFilter from "../search/SideFilter";
import helpers from "../../services/helpers";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import AdvancedSearch from "./components/AdvancedSearch";
import {
  getGenreList,
  getLanguageList,
  getPlatformList,
  getPlatformListPartner,
} from "../../slices/auth";
import { DEFAULT_ADV_FILTER } from "./partnerHelper";
import SearchBar from "./components/SearchBar";
import { getMediaSearch, getPersonSearch } from "../../slices/search";
import CommonMediumTiles from "../common/CommonMediumTiles";
import CastCrew from "../media/CastCrew";
import ImageWithFallback from "../common/ImageWithFallback";

const PAGINATION_TYPE = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
};

const loadingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

function AdvancedSearchResultsPage() {
  const [page, setPage] = useState(1);
  // const [pageLimit, setPageLimit] = useState(20);
  // const [tempFilter, setTempFilter] = useState({});
  const [searchData, setSearchData] = useState({});
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchDataList, setSearchDataList] = useState([]);
  const [open, setOpen] = useState(false);
  const [isQueryFullSearch, setIsQueryFullSearch] = useState(false);
  const [isQuerySearch, setIsQuerySearch] = useState(false);
  const [mediaSearchData, setMediaSearchData] = useState([]);
  const [personSearchData, setPersonSearchData] = useState([]);
  const [input, setInput] = useState("");
  const [autoSearchData, setAutoSearchData] = useState([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { advFilters } = useSelector((state) => state.frenziPartner);
  const _global = useSelector((state) => state.global);
  const auth = useSelector((state) => state.auth);

  // const startIndex = (page - 1) * pageLimit;
  // const endIndex = Math.min(startIndex + pageLimit, pagination?.total_entries);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave? Your changes might not be saved!";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers (pre-2015)
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(getLanguageList());
    dispatch(
      getPlatformListPartner({
        page: 1,
        pagelimit: 100,
        is_public: _global?.is_public,
      })
    );
    dispatch(
      getPlatformList({
        page: 1,
        pagelimit: 100,
        is_public: _global?.is_public,
      })
    );
    dispatch(getGenreList());
  }, []);

  let languageList =
    auth.languageList &&
    auth.languageList.languages &&
    auth.languageList.languages.length > 5
      ? auth.languageList.languages
      : auth.allPreferences?.languages
      ? auth.allPreferences?.languages
      : [];

  // const detectDevice = () => {
  //   if (window.matchMedia("(max-width: 767px)").matches) {
  //     return PAGINATION_TYPE.SMALL;
  //   } else if (
  //     window.matchMedia("(min-width: 768px) and (max-width: 1024px)").matches
  //   ) {
  //     return PAGINATION_TYPE.MEDIUM;
  //   } else {
  //     return PAGINATION_TYPE.LARGE;
  //   }
  // };

  // const handlePageLimitChange = (event) => {
  //   setPageLimit(event.target.value);
  // };

  const getData = (string, page, isNew = false, isFilter = true) => {
    setIsLoading(true);
    if (!string || string == "") {
      setIsQuerySearch(false);
      setIsQueryFullSearch(false);
      let allFilters = helpers.getSearchFilters(advFilters);
      const body = {
        body: {},
        page: page,
      };
      if (isFilter) {
        body.body = allFilters;
      }
      dispatch(getAdvancedSearchData(body))
        .then((resp) => {
          const payloadData = resp?.payload?.data || null;
          if (payloadData) {
            setSearchData(payloadData);
            if (payloadData?.results && payloadData.results.length > 0) {
              if (isNew) {
                setSearchDataList([...payloadData.results]);
              } else {
                setSearchDataList([...searchDataList, ...payloadData.results]);
              }
            } else {
              setIsError(true);
            }
            setIsLoading(false);
          } else {
            setIsError(true);
            setIsLoading(false);
            // helpers.toastError('No data found!!!')
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          console.log(err);
        });
    } else {
      setIsQuerySearch(true);
      const body = {
        query: string,
        page: page,
        pagelimit: 20,
        is_public: _global.is_public,
      };
      dispatch(getMediaSearch(body))
        .then((resp) => {
          setIsLoading(false);
          if (
            resp?.payload?.data &&
            resp?.payload?.data?.results &&
            resp?.payload?.data?.results.length
          ) {
            if (page == 1) {
              setMediaSearchData(resp?.payload?.data?.results);
            } else {
              setMediaSearchData([
                ...mediaSearchData,
                ...resp?.payload?.data?.results,
              ]);
            }
          } else {
            setIsError(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(true);
          console.log(err);
        });
      if (!isQueryFullSearch) {
        dispatch(getPersonSearch(body))
          .then((resp) => {
            setIsLoading(false);
            if (
              resp?.payload?.data &&
              resp?.payload?.data?.results &&
              resp?.payload?.data?.results.length
            ) {
              setPersonSearchData(resp?.payload?.data?.results);
            } else {
              setIsError(true);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            setIsError(true);
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    const string = searchParams.get("q");
    if (string != input) {
      setInput(string);
      setSearchDataList([]);
      setSearchData({});
      setPage(1);
      getData(string, 1, true);
    } else {
      getData(string, page);
    }
    setIsError(false);
  }, [page, searchParams]);

  function debounce(func, delay) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const handleScrollNew = () => {
    const elements = document.getElementsByClassName("tile-container");
    if (elements.length > 0) {
      const lastElement = elements[elements.length - 1];
      const rect = lastElement.getBoundingClientRect();

      const isVisible =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);
      if (isVisible && !isError) {
        setPage((prev) => {
          return prev + 1;
        });
      }
    }
  };

  const debouncedScrollHandler = debounce(handleScrollNew, 200);

  useEffect(() => {
    window.addEventListener("scroll", debouncedScrollHandler, {
      passive: true,
    });
    return () => window.removeEventListener("scroll", debouncedScrollHandler);
  }, [page]);

  const handleChangeSort = (event) => {
    dispatch(handleFilters({ ...advFilters, sort: event.target.value }));
    handleApply(true);
    // setSearchMediaFilter({ ...searchMediaFilter, sort: event.target.value });
  };

  const loadMore = () => {
    if (!isLoading && !isError) {
      setPage((prev) => {
        return prev + 1;
      });
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleClose();
      navigate(`/advanced-search/results?q=${input || ""}`);
    }
  };

  const handleApply = (isFilter, onlyRedirect = false) => {
    handleClose();
    dispatch(resetPartnerData());
    setSearchDataList([]);
    setSearchData({});
    navigate(`/advanced-search/results${onlyRedirect ? "?referrer=true" : ""}`);
    setInput("");
    setPage(1);
    if (!onlyRedirect) {
      getData("", 1, true, isFilter);
    }
    setIsError(false);
  };

  const handleSeacrh = (val) => {
    setIsSearchLoading(true);
    const body = {
      query: val,
    };
    dispatch(getAutoSearchData(body))
      .then((resp) => {
        setIsSearchLoading(false);
        if (resp?.payload?.data && resp?.payload?.data?.results) {
          setAutoSearchData(resp?.payload?.data?.results);
        }
      })
      .catch((err) => {
        setIsSearchLoading(false);
        console.log(err);
      });
  };

  const handleNextClick = (input) => {
    setIsQueryFullSearch(true);
  };

  const getSearchStrings = (input) => {
    let text = [];
    if (input) {
      text.push({ label: "Query", value: input });
    }
    if (advFilters.contributors && advFilters.contributors[0]) {
      let value = advFilters.contributors.map((x) => x.name).join(", ");
      text.push({ label: "Cast & Crew", value: value });
    }
    if (advFilters.ott_platforms && advFilters.ott_platforms[0]) {
      let value = advFilters.ott_platforms
        .map((x) => x.platform_name)
        .join(", ");
      text.push({ label: "OTT", value: value });
    }
    if (advFilters.available_languages && advFilters.available_languages[0]) {
      let lang = languageList.filter((x) => {
        if (advFilters.available_languages.includes(x.code)) {
          return true;
        } else {
          return false;
        }
      });
      let value = advFilters.available_languages.join(", ");
      if (lang && lang.length > 0) {
        value = lang.map((x) => x.language).join(", ");
      }
      text.push({ label: "Language", value: value });
    }
    if (advFilters.genres && advFilters.genres[0]) {
      let value = advFilters.genres.join(", ");
      text.push({ label: "Genre", value: value });
    }
    if (advFilters.content_type && advFilters.content_type[0]) {
      let value = advFilters.content_type
        .map((x) => {
          if (x == "movie") {
            return "movies";
          }
          if (x == "tv") {
            return "webseries";
          }
        })
        .join(", ");
      text.push({ label: "Content Type", value: value });
    }
    if (advFilters.sort) {
      let val = "rating";
      if (advFilters.sort == "d") {
        val = "release date";
      }
      if (advFilters.sort == "l") {
        val = "popularity";
      }
      text.push({ label: "Sort By", value: val });
    }
    if (advFilters.rating) {
      text.push({ label: "Rating", value: advFilters.rating });
    }
    if (advFilters.from_year || advFilters.to_year) {
      text.push({
        label: "Year",
        value: `${advFilters.from_year} - ${advFilters.to_year}`,
      });
    }
    return text;
  };

  const handleProfileClick = (data) => {
    const castData = {
      ...data,
      name: data?.name || data?.title,
      label: data?.name || data?.title,
      value: data?.uuid,
    };
    dispatch(handleFilters({ ...advFilters, contributors: [castData] }));
    handleApply(true, true);
  };

  console.log("searchDataList---",searchDataList)

  return (
    <>
      <MyHelmet
        title={"Partner search page"}
        // description={
        //   "Unveil the Hottest OTT Trends, Stay Updated on the Latest Streaming Buzz and Discover What's Trending in the OTT Worlds"
        // }
      />
      <Grid container className="new-container pb-10">
        <div className="partner-search-box-container">
          <Grid container style={{ alignItems: "center" }} className="mb-20">
            <Grid item flex={1}>
              <Grid
                container
                flexWrap={"nowrap"}
                style={{ alignItems: "center" }}
              >
                <Grid item>
                  <KeyboardBackspaceRoundedIcon
                    onClick={() => {
                      dispatch(handleFilters(DEFAULT_ADV_FILTER));
                      dispatch(handleSearchBarFilters([]));
                      const isReferrer = searchParams.get("referrer") || false;
                      if (isReferrer) {
                        navigate(-1);
                      }
                      const isPartner = searchParams.get("isPartner") || false;
                      if (isPartner) {
                        navigate("/partner");
                      } else {
                        navigate("/advanced-search");
                      }
                    }}
                    fontSize="large"
                    className="pointer mr-10 ml-10 scale-1"
                  />
                </Grid>
                <Grid item>
                  <Grid container>
                    <Typography className="fw-700 fs-28 lh-28 text-selected">
                      search results
                    </Typography>
                  </Grid>
                  <Grid container>
                    {getSearchStrings(input).map((x, i) => {
                      return (
                        <Typography key={i} className="fw-500 fs-18 lh-28">
                          <span className="text-selected">{x.label} : </span>
                          <span className="text-muted mr-10">{x.value}</span>
                        </Typography>
                      );
                    })}
                  </Grid>
                </Grid>

                {/* <Grid item>
                    <div className="search-container">
                      <Grid
                        container
                        justifyContent={"center"}
                        sx={{
                          marginTop: { xs: "10px" },
                          marginBottom: "10px",
                          width: "100%",
                        }}
                      >
                        <SearchBar
                          suggestions={searchData}
                          isLoading={isLoading}
                          onChange={(val) => handleSeacrh(val)}
                          onKeyUp={(e) => handleKeyUp(e)}
                          setInput={setInput}
                          input={input}
                          isNative={isNative}
                        />
                      </Grid>
                    </div>
                  </Grid> */}
              </Grid>
            </Grid>

            <Grid item>
              <TuneRoundedIcon
                onClick={() => {
                  setOpen(true);
                }}
              />
            </Grid>
          </Grid>
          <div className="search-results-container">
            {/* <Grid
                            container
                            justifyContent={"space-between"}
                            style={{ alignItems: "center" }}
                        >
                            <div>
                                <Grid item>
                                    <div>
                                        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
                                            <InputLabel id="demo-select-small-label">
                                                sort by
                                            </InputLabel>
                                            <Select
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={advFilters.sort}
                                                label="sort by"
                                                onChange={handleChangeSort}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value={"r"}>rating</MenuItem>
                                                <MenuItem value={"l"}>popularity</MenuItem>
                                                <MenuItem value={"d"}>release date</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </div>
                            <div className="pointer">
                                <TuneRoundedIcon
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                />
                            </div>
                        </Grid> */}
            {isQuerySearch ? (
              isQueryFullSearch ? (
                <div>
                  <Grid container justifyContent="flex-start">
                    {mediaSearchData.map((x, index) => {
                      if (!x.poster || x.poster === "") {
                        return null;
                      }
                      return (
                        <Grid
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          title={x?.title}
                          item
                          key={index}
                          id={`${index + 1}-movie-list-item`}
                          className="tile-container"
                        >
                          <Grid
                            sx={{
                              maxWidth: { xs: 140, sm: 180 },
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              display: "inline-block",
                            }}
                          >
                            <Grid item className="scale-1">
                              <Grid item className="movie-container">
                                <Link
                                  target="_blank"
                                  onClick={() => {
                                    if (
                                      x.media_type === "movie" ||
                                      x.media_type === "tv"
                                    ) {
                                      helpers.mediaClick(
                                        x?.title,
                                        x.media
                                          ? x.media
                                          : x.uuid
                                          ? x.uuid
                                          : "",
                                        "partner_search"
                                      );
                                    } else if (
                                      x.watchlist_id &&
                                      x.watchlist_id !== ""
                                    ) {
                                      helpers.watchlistClick(
                                        x?.category_name || x?.watchlist,
                                        x?.watchlist_id,
                                        "partner_search"
                                      );
                                    }
                                  }}
                                  to={helpers.getDynamicLink({
                                    ...x,
                                    uuid: x.id,
                                  })}
                                >
                                  {/* <Box
                                                                        component="img"
                                                                        className='d-block'
                                                                        sx={{
                                                                            height: 270,
                                                                            width: 180,
                                                                            maxHeight: { xs: 200, sm: 270 },
                                                                            maxWidth: { xs: 140, sm: 180 },
                                                                            borderRadius: "7px",
                                                                            objectFit: 'cover',
                                                                            objectPosition: 'top'
                                                                        }}
                                                                        alt={x?.title || 'poster'}
                                                                        src={x.poster ? helpers.getFullUrl(x.poster, 300) : helpers.getDefaultImage('trendingmedia')}
                                                                    /> */}
                                  <ImageWithFallback
                                    sx={{
                                      height: 270,
                                      width: 180,
                                      maxHeight: { xs: 200, sm: 270 },
                                      maxWidth: { xs: 140, sm: 180 },
                                      borderRadius: "7px",
                                      objectFit: "cover",
                                      objectPosition: "top",
                                    }}
                                    alt={x?.title || "poster"}
                                    src={
                                      x.poster
                                        ? helpers.getFullUrl(x.poster, 300)
                                        : helpers.getDefaultImage(
                                            "trendingmedia"
                                          )
                                    }
                                    data={{
                                      name: x?.title,
                                      language: x?.languages.join(", "),
                                      year: x?.release_year,
                                    }}
                                  />
                                </Link>
                              </Grid>
                            </Grid>
                            {x?.score}
                          </Grid>
                        </Grid>
                      );
                    })}

                    {isLoading ? (
                      <>
                        {loadingArray.map((x) => {
                          return (
                            <Grid
                              xs={6}
                              sm={4}
                              md={3}
                              lg={2}
                              item
                              key={x}
                              className="tile-container"
                            >
                              <Grid
                                sx={{
                                  maxWidth: { xs: 140, sm: 180 },
                                  paddingTop: "20px",
                                  paddingBottom: "20px",
                                  display: "inline-block",
                                }}
                              >
                                <Skeleton
                                  animation="wave"
                                  variant="rectangular"
                                  sx={{
                                    height: 270,
                                    width: 180,
                                    maxHeight: { xs: 200, sm: 270 },
                                    maxWidth: { xs: 140, sm: 180 },
                                    borderRadius: "7px",
                                  }}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {!isError ? (
                    <Grid container justifyContent={"center"}>
                      <Button
                        style={{ marginBottom: "10px" }}
                        onClick={() => loadMore()}
                        disabled={isError}
                        className="text-light bg-selected rounded-btn mt-10 mr-10 mw-100"
                      >
                        load more
                      </Button>
                    </Grid>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div>
                  <Grid container justifyContent="flex-start mt-20">
                    {isLoading ? (
                      <Grid container justifyContent={"center"}>
                        <Grid item>
                          <CircularProgress size={70} color="error" />
                        </Grid>
                      </Grid>
                    ) : (
                      ""
                    )}
                    {mediaSearchData.length == 0 &&
                    personSearchData.length == 0 &&
                    !isLoading ? (
                      <p className="text-selected w-100 text-center mt-50 fs-30">
                        No Results Found !!!
                      </p>
                    ) : (
                      ""
                    )}
                    {mediaSearchData.length > 0 && (
                      <>
                        <CommonMediumTiles
                          refPage={"search"}
                          heading={
                            <>
                              titles based on{" "}
                              <span className="text-selected">{input}</span>
                            </>
                          }
                          data={mediaSearchData}
                          allowAll={false}
                          isNextBtn={true}
                          handleNextBtn={() => handleNextClick(input)}
                          hideWithoutPoster={true}
                        />
                      </>
                    )}

                    {personSearchData.length > 0 && (
                      <>
                        <CastCrew
                          heading={
                            <>
                              people similar to
                              <span className="text-selected">
                                {" "}
                                {`${input}`}
                              </span>
                            </>
                          }
                          subheading={
                            <span className="text-muted">
                              other film folks you may want to look up
                            </span>
                          }
                          data={personSearchData}
                          hideWithoutPoster={true}
                          onProfileClick={(data) => handleProfileClick(data)}
                          isProfileClick={true}
                          // isNextBtn={true}
                          // handleNextBtn={() => console.log('')}
                        />
                      </>
                    )}
                  </Grid>
                </div>
              )
            ) : (
              <div>
                <Grid container justifyContent="flex-start">
                  {searchDataList && searchDataList?.length ? (
                    searchDataList.map((x, index) => {
                      if (!x.poster || x.poster === "") {
                        return null;
                      }
                      return (
                        <Grid
                          xs={6}
                          sm={4}
                          md={3}
                          lg={2}
                          title={x?.title}
                          item
                          key={index}
                          id={`${index + 1}-movie-list-item`}
                          className="tile-container"
                        >
                          <Grid
                            sx={{
                              maxWidth: { xs: 140, sm: 180 },
                              paddingTop: "20px",
                              paddingBottom: "20px",
                              display: "inline-block",
                            }}
                          >
                            {/* <MediumTile
                            x={{
                              ...data,
                              poster: data?.poster_path
                            }}
                            isLoading={isLoading} /> */}

                            <Grid item className="scale-1">
                              <Grid item className="movie-container">
                                <Link
                                  target="_blank"
                                  onClick={() => {
                                    if (
                                      x.media_type === "movie" ||
                                      x.media_type === "tv"
                                    ) {
                                      helpers.mediaClick(
                                        x?.title,
                                        x.media
                                          ? x.media
                                          : x.uuid
                                          ? x.uuid
                                          : "",
                                        "partner_search"
                                      );
                                    } else if (
                                      x.watchlist_id &&
                                      x.watchlist_id !== ""
                                    ) {
                                      helpers.watchlistClick(
                                        x?.category_name || x?.watchlist,
                                        x?.watchlist_id,
                                        "partner_search"
                                      );
                                    }
                                  }}
                                  to={helpers.getDynamicLink({
                                    ...x,
                                    uuid: x.id,
                                  })}
                                >
                                  {/* <Box
                                    component="img"
                                    className="d-block"
                                    sx={{
                                      height: 270,
                                      width: 180,
                                      maxHeight: { xs: 200, sm: 270 },
                                      maxWidth: { xs: 140, sm: 180 },
                                      borderRadius: "7px",
                                      objectFit: "cover",
                                      objectPosition: "top",
                                    }}
                                    alt={x?.title || "poster"}
                                    src={
                                      x.poster
                                        ? helpers.getFullUrl(x.poster, 300)
                                        : helpers.getDefaultImage(
                                            "trendingmedia"
                                          )
                                    }
                                  /> */}
                                  <ImageWithFallback
                                    sx={{
                                      height: 270,
                                      width: 180,
                                      maxHeight: { xs: 200, sm: 270 },
                                      maxWidth: { xs: 140, sm: 180 },
                                      borderRadius: "7px",
                                      objectFit: "cover",
                                      objectPosition: "top",
                                    }}
                                    alt={x?.title || "poster"}
                                    src={
                                      x.poster
                                        ? helpers.getFullUrl(x.poster, 300)
                                        : helpers.getDefaultImage(
                                            "trendingmedia"
                                          )
                                    }
                                    images={x?.images ? x?.images?.posters : []}
                                    data={{
                                      name: x?.title,
                                      language: x?.language,
                                      year: x?.release_date ? new Date(x?.release_date).getFullYear() : '',
                                    }}
                                  />
                                </Link>
                              </Grid>
                            </Grid>
                            {x?.score}
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : !isLoading ? (
                    <p className="text-selected w-100 text-center mt-50 fs-30">
                      No Results Found !!!
                    </p>
                  ) : (
                    ""
                  )}

                  {isLoading ? (
                    <>
                      {loadingArray.map((x) => {
                        return (
                          <Grid
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            item
                            key={x}
                            className="tile-container"
                          >
                            <Grid
                              sx={{
                                maxWidth: { xs: 140, sm: 180 },
                                paddingTop: "20px",
                                paddingBottom: "20px",
                                display: "inline-block",
                              }}
                            >
                              <Skeleton
                                animation="wave"
                                variant="rectangular"
                                sx={{
                                  height: 270,
                                  width: 180,
                                  maxHeight: { xs: 200, sm: 270 },
                                  maxWidth: { xs: 140, sm: 180 },
                                  borderRadius: "7px",
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </Grid>
                {!isError ? (
                  <Grid container justifyContent={"center"}>
                    <Button
                      style={{ marginBottom: "10px" }}
                      onClick={() => loadMore()}
                      disabled={isError}
                      className="text-light bg-selected rounded-btn mt-10 mr-10 mw-100"
                    >
                      load more
                    </Button>
                  </Grid>
                ) : (
                  ""
                )}
              </div>
            )}

            {/* <div className="pagination-container">
                <Grid
                  container
                  justifyContent={"space-between"}
                  style={{ alignItems: "center" }}
                >
                  <Grid item>
                    <Grid container style={{ alignItems: "center" }}>
                      <div>
                        <p>
                          showing results from {startIndex + 1} - {endIndex} out
                          of {pagination?.total_entries}
                        </p>
                      </div>
                      <div>
                        <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                          <InputLabel id="demo-select-small-label">
                            data per page
                          </InputLabel>
                          <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={pageLimit}
                            label="data per page"
                            onChange={handlePageLimitChange}
                          >
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                            <MenuItem value={500}>500</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                  </Grid>
  
                  <Pagination
                    count={pagination?.total_pages || 10}
                    page={page}
                    // siblingCount={2}
                    onChange={(e, val) => setPage(val)}
                    variant="outlined"
                    color="secondary"
                    shape="rounded"
                    size={detectDevice()}
                  />
                </Grid>
              </div> */}

            {/* advanced search */}
            <AdvancedSearch
              open={open}
              searchData={autoSearchData}
              // searchData={searchData}
              isLoading={isSearchLoading}
              input={input}
              handleClose={handleClose}
              handleSeacrh={handleSeacrh}
              handleKeyUp={handleKeyUp}
              setInput={setInput}
              isNative={true}
              handleApply={handleApply}
            />
          </div>
        </div>
      </Grid>
    </>
  );
}

export default AdvancedSearchResultsPage;
