import { Grid, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProgressBar from "../component/ProgressBar";
import helpers from "../../services/helpers";
import BookmarkAction from "../watchlist/BookmarkAction";

const FallbackComponent = ({ src, alt, sx, data }) => {
  return (
    <Box
      sx={sx}
      data={src}
      style={{
        backgroundColor: "#3f3d3d",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p
        style={{
          color: "#e94560",
          fontWeight: "bold",
          width: "100%",
          textAlign: "center",
        }}
      >
        {data?.name || alt}
      </p>
      <p
        style={{
          color: "#e94560",
          width: "100%",
          margin: 0,
          textAlign: "center",
        }}
      >
        {data?.language} | {data?.year}
      </p>
    </Box>
  );
};

export default function MediumTile(props) {
  const {
    x,
    hideBookmark,
    hideFooter,
    selectedPlatformOttName,
    isLoading = false,
  } = props;
  const [addedId, setAddeddId] = useState("");

  const [isImageAvailable, setIsImageAvailable] = useState(null);

  useEffect(() => {
    // Function to check if image exists
    const checkImageUrl = async () => {
      try {
        const res = await helpers.checkIfFileExists(
          process.env.REACT_APP_S3_TEMP_BUCKET_NAME,
          x.poster
        );

        setIsImageAvailable(res);
      } catch (error) {
        console.error("Error checking image:", error);
        setIsImageAvailable(false);
      }
    };

    checkImageUrl();
  }, [x.poster]);

  return (
    <>
      <Grid item className="scale-1">
        <Grid item className="movie-container">
          <Link
            onClick={() => {
              if (x.media_type === "movie" || x.media_type === "tv") {
                helpers.mediaClick(
                  x?.title,
                  x.media ? x.media : x.uuid ? x.uuid : "",
                  props?.refPage
                );
              } else if (x.watchlist_id && x.watchlist_id !== "") {
                helpers.watchlistClick(
                  x?.category_name || x?.watchlist,
                  x?.watchlist_id,
                  props?.refPage
                );
              }
            }}
            to={helpers.getDynamicLink(x)}
          >
            {!isLoading ? (
              <>
                {isImageAvailable ? (
                  <Box
                    component="img"
                    className="d-block"
                    sx={{
                      height: 210,
                      width: 140,
                      maxHeight: { xs: 150, sm: 210 },
                      maxWidth: { xs: 100, sm: 140 },
                      borderRadius: "7px",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    alt={x?.title || "poster"}
                    src={
                      x.poster
                        ? helpers.getFullUrl(x.poster, 300)
                        : helpers.getDefaultImage("trendingmedia")
                    }
                  />
                ) : (
                  <FallbackComponent
                    src={x.poster}
                    alt={x?.title}
                    sx={{
                      height: 210,
                      width: 140,
                      maxHeight: { xs: 150, sm: 210 },
                      maxWidth: { xs: 100, sm: 140 },
                      borderRadius: "7px",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    data={{
                      name: x?.title,
                      language: x?.language,
                      year: x?.release_date
                        ? new Date(x?.release_date).getFullYear()
                        : "",
                    }}
                  /> // If the image is not available, render the fallback component
                )}
              </>
            ) : (
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{
                  height: 210,
                  width: 140,
                  maxHeight: { xs: 150, sm: 210 },
                  maxWidth: { xs: 100, sm: 140 },
                  borderRadius: "7px",
                }}
              />
            )}
          </Link>
          {/* {!hideBookmark && x.media_type && (x.media_type === "movie" || x.media_type === "tv") &&
                        <BookmarkAction data={x} addedId={addedId} setAddedId={setAddeddId} />
                    } */}
          {!isLoading ? (
            <Grid item xs={12} className="pt-0">
              <Grid
                container
                alignItems={"center"}
                sx={{ minWidth: { xs: "30px", sm: "50px", xl: "60px" } }}
                flexWrap={"nowrap"}
                className="bottom-right w-100"
              >
                <p
                  style={{ textTransform: "uppercase" }}
                  className="m-0 fs-10 pl-10"
                >
                  {" "}
                  {x.media_type ? x.media_type : ""}{" "}
                </p>
                <p
                  style={{ marginTop: "-3px" }}
                  className="mr-0 ml-0 mb-0 fs-10 pl-5 pr-5"
                >
                  {" "}
                  |{" "}
                </p>{" "}
                <p
                  className="m-0 pr-10 fs-10"
                  style={{ textTransform: "uppercase" }}
                >
                  {" "}
                  {x.language ? x.language : ""}
                </p>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {!hideFooter && (
          <Grid
            container
            className="jc-sb"
            sx={{ marginTop: "5px" }}
            alignItems={"center"}
          >
            <Grid item xs={8.5}>
              {(x.person || x.watchlist_id) && x.title ? (
                <Typography
                  className="fs-14 ellipsis lh-21 fw-400"
                  sx={{ marginTop: "-3px" }}
                >
                  {x.title}
                </Typography>
              ) : (
                <Typography
                  title={
                    selectedPlatformOttName ||
                    (x.data && x.data[0] && x.data[0].ott_name
                      ? x.data[0].ott_name.replace("Amazon ", "") +
                        `${x.data.length > 1 ? ` +${x.data.length - 1}` : ""}`
                      : "")
                  }
                  className="fs-14 ellipsis lh-21 fw-400"
                  sx={{ marginTop: "-3px" }}
                >
                  {/* {selectedPlatformOttName || (x.data && x.data[0] && x.data[0].ott_name ? x.data[0].ott_name.replace("Amazon ", '') + `${x.data.length > 1 ? ` +${x.data.length - 1}` : ''}` : '')} */}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3}>
              {x.rating && x.rating > 0 ? (
                <ProgressBar value={x.rating ? Number(x.rating) * 10 : 0} />
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
