import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import helpers, { MOENGAGE_ATTRIBUTE } from "../../services/helpers";
import {
  clearMessages,
  getAllWatchLists,
  getCollectionMetadata,
  getComingSoon,
  getGenreMedia,
  getHandPicked,
  getLanguageMedia,
  getNewlyAdded,
  getPlatformMedia,
  getPopularToday,
  getTrendingInFriends,
} from "../../slices/trending";
import { getAllPreferences, getOnboardingMediaCount } from "../../slices/auth";
import { clearMessages as clearMessagesWatchlist } from "../../slices/watchlist";
import { clearMessages as clearMessagesMedia } from "../../slices/media";

import "./trending.scss";
import { togglePartnerJourneyModal } from "../../slices/global";
import MyAds from "../adsense/MyAds";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HandpickSliderNew } from "./HandpickSliderNew";
import "swiper/css/effect-fade";
import MyHelmet from "../common/MyHelmet";
import LinearLoader from "../common/LinearLoader";
import loadable from '@loadable/component';
import { isMobileDevice } from "../../services/Utils";

const LoadableHandpickDefault = loadable(() => import('./HandpickDefault'), {
  preload: true
});
const LoadableTopTen = loadable(() => import('./TopTen'));
const LoadableTrendingPlatform = loadable(() => import('./TrendingPlatform'));
const LoadableNewlyAdded = loadable(() => import('./NewlyAdded'));
const LoadablePreferLanguage = loadable(() => import('./PreferLanguage'));
const LoadablePreferMood = loadable(() => import('./PreferMood'));
const LoadableCommonDetailTileListing = loadable(() => import('../common/CommonDetailTileListing'));
const LoadableJoinWatchlist = loadable(() => import('./JoinWatchlist'));
const LoadableFrenziPopular = loadable(() => import('./FrenziPopular'));
const LoadableCollectionMedia = loadable(() => import('./CollectionMedia'));
const LoadableFriendsWatching = loadable(() => import('./FriendsWatching'));
const LoadableYourWatchlist = loadable(() => import('./YourWatchlist'));

LoadableHandpickDefault.preload();

function Trending() {
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [selectedPlatformName, setSelectedPlatformName] = useState("");
  const [selectedPlatformOttName, setSelectedPlatformOttName] = useState("")
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedGenreName, setSelectedGenreName] = useState("")
  const [selectedLanguageName, setSelectedLanguageName] = useState("")
  const [selectedGenre, setSelectedGenre] = useState("");
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [opened, setOpened] = useState(false);
  const [removedId, setRemovedId] = useState([]);


  const trending = useSelector((state) => state.trending);
  const watchlist = useSelector((state) => state.watchlist);
  const media = useSelector((state) => state.media);
  const auth = useSelector((state) => state.auth);
  const _global = useSelector((state) => state.global);

  const dispatch = useDispatch();

  useEffect(() => {
    executeFirst();
    let isDone = false;
    setTimeout(() => {
      let y = localStorage.getItem("fsy") || 0;
      window.scrollTo(0, y);
      isDone = true;
    }, 0)

    function handleScroll() {
      if (isDone) {
        const scrolledY = window.scrollY;
        localStorage.setItem("fsy", scrolledY);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (auth.successMesg && auth.successMesg !== "") {
      dispatch(getAllPreferences({ is_public: _global.is_public }));
      dispatch(getOnboardingMediaCount());
    }
  }, [auth]);

  useEffect(() => {
    if (trending.errMesg && trending.errMesg !== "") {
      helpers.toastError(trending.errMesg);
      dispatch(clearMessages());
    }
    if (trending.successMesg && trending.successMesg !== "") {
      helpers.toastSuccess(trending.successMesg);
      dispatch(clearMessages());
    }
    window.onscroll = function (e) {
      if (!localStorage.getItem("opened")) {
        localStorage.setItem("opened", true);
        setOpened(true);
      } else {
        setOpened(true);
      }
    }
  }, [trending]);

  useEffect(() => {
    if (media.errMesg && media.errMesg !== "") {
      helpers.toastError(media.errMesg);
      dispatch(clearMessagesMedia());
    }
    if (media.successMesg && media.successMesg !== "") {
      if (media.successMesg !== "Like status changed") {
        helpers.toastSuccess(media.successMesg);
      }
      dispatch(clearMessagesMedia());
    }
  }, [media]);

  useEffect(() => {
    if (watchlist.errMesg && watchlist.errMesg !== "") {
      helpers.toastError(watchlist.errMesg);
      dispatch(clearMessagesWatchlist());
    }
    if (watchlist.successMesg && watchlist.successMesg !== "") {
      helpers.toastSuccess(watchlist.successMesg);
      dispatch(clearMessagesWatchlist());
    }
  }, [watchlist]);

  useEffect(() => {
    if (selectedPlatform && selectedPlatform !== "") {
      var body = {
        page: 1,
        pagelimit: 10,
        id: selectedPlatform,
      };
      dispatch(getPlatformMedia(body));
    }
  }, [selectedPlatform]);

  useEffect(() => {
    if (selectedLanguage && selectedLanguage !== "") {
      var body = {
        page: 1,
        pagelimit: 10,
        id: selectedLanguage,
      };
      dispatch(getLanguageMedia(body));
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (selectedGenre && selectedGenre !== "") {
      var body = {
        page: 1,
        pagelimit: 10,
        id: selectedGenre,
      };
      dispatch(getGenreMedia(body));
    }
  }, [selectedGenre]);

  const executeFirst = () => {
    var body = {
      page: 1,
      pagelimit: 10,
      is_free: false,
    };
    dispatch(getHandPicked({ ...body, no_filter: true }));
    refreshMovieData(body);
    dispatch(getOnboardingMediaCount());
    if (!localStorage.getItem("FirstSeen")) {
      helpers.trackMoengageEvent("FR3_FirstSeen", {
        home_page_load: MOENGAGE_ATTRIBUTE.YES,
      });
      localStorage.setItem("FirstSeen", true);
    }

    if (
      !localStorage.getItem("is_partner_journey_modal_shown") &&
      !_global.isLoginModalOpen
    ) {
      setTimeout(() => {
        dispatch(togglePartnerJourneyModal(true));
      }, 7000);
    }
  }

  const refreshMovieData = (body) => {
    dispatch(getNewlyAdded(body));
    dispatch(getAllPreferences({ is_public: _global.is_public }));
    dispatch(getPopularToday(body));
    dispatch(getTrendingInFriends(body));
    dispatch(getComingSoon(body));
    dispatch(getAllWatchLists(body));
    dispatch(getCollectionMetadata({ is_trending: 1 }));
  };

  const handleStop = () => {
    controlledSwiper.autoplay.stop();
  };

  const handlePlay = () => {
    controlledSwiper.autoplay.start();
  };

  return (

    <>
      <MyHelmet
        title={"What's trending in the OTT world"}
        description={
          "Unveil the Hottest OTT Trends, Stay Updated on the Latest Streaming Buzz and Discover What's Trending in the OTT Worlds"
        }
      />
      {(trending.isLoading || auth.isLoading || media.isLoading) && (
        <LinearLoader />
      )}
      <Grid
        item
        xs={12}
        className="app-section"
        style={{ overflow: "hidden" }}>

        {trending.handpickedPageData?.handpicked_response &&
          trending.handpickedPageData.handpicked_response.response &&
          trending.handpickedPageData.handpicked_response.response.length >
          0 ? (
          <>
            <Grid item xs={12}>
              <Swiper
                centeredSlides={true}
                fadeEffect={{ crossFade: true }}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: true,
                }}
                effect={"fade"}
                modules={[Autoplay, Navigation, EffectFade, Pagination]}
                className="new-swiper"
                onSwiper={setControlledSwiper}
                pagination={{
                  clickable: true,
                }}
              >
                {trending.handpickedPageData.handpicked_response.response.map((x, i) => {
                  if (!removedId.includes(x.media)) {
                    return <SwiperSlide key={i}>
                      <HandpickSliderNew
                        data={x}
                        isShowTag={false}
                        setRemovedIds={(id) => setRemovedId([...removedId, id])}
                        handlePlay={handlePlay}
                        handleStop={handleStop}
                      />
                    </SwiperSlide>
                  }
                })}
              </Swiper>
            </Grid>
          </>
        ) : (
          <LoadableHandpickDefault />
        )}
      </Grid>
      <Grid container className="new-container mt-30">
        <Grid flex={1} className="left-container">
          <>
            <Grid
              container
              className="app-container pb-40"
              justifyContent={"center"}
            >
              <Grid item xs={12} className="app-section">
                <LoadableTopTen is_free={false} isTopTen={true} heading={<p><span className="text-selected">top 10</span> on ott this week</p>} />

                <LoadableTrendingPlatform
                  isShowHeading={true}
                  isLoading={trending.isLoading || auth.isLoading || media.isLoading}
                  platforms={
                    auth.allPreferences && auth.allPreferences.platforms
                      ? auth.allPreferences.platforms
                      : []
                  }
                  hideAds={true}
                  setSelectedPlatform={setSelectedPlatform}
                  setSelectedPlatformOttName={setSelectedPlatformOttName}
                  selectedPlatform={selectedPlatform}
                  selectedPlatformName={selectedPlatformName}
                  setSelectedPlatformName={setSelectedPlatformName}
                  selectedPlatformOttName={selectedPlatformOttName}
                  data={
                    trending.platformMediaData ? trending.platformMediaData : []
                  }
                  withAd={true}
                  heading={<><span>trending on </span> <span className='text-selected'>{selectedPlatformOttName}</span></>}

                />

                <Grid container justifyContent={"center"}>
                  <MyAds type="banner_2" mobtype={"mobile_1"} height={100} slotId={'8155963946'} adv_type={"web_trending_fold_ad"} />
                </Grid>

                {trending.freshPageData &&
                  trending.freshPageData.results &&
                  trending.freshPageData.results.length > 0 && (
                    <LoadableNewlyAdded data={trending.freshPageData.results} heading={<><span className="text-selected">fresh</span><span> arrivals</span></>} />
                  )}

                <LoadablePreferLanguage
                  languages={
                    auth.allPreferences && auth.allPreferences.languages
                      ? auth.allPreferences.languages
                      : []
                  }
                  refPage={"Trending"}
                  setSelectedLanguage={setSelectedLanguage}
                  selectedLanguage={selectedLanguage}
                  selectedLanguageName={selectedLanguageName}
                  setSelectedLanguageName={setSelectedLanguageName}
                  data={trending.langMediaData ? trending.langMediaData : []}
                  withAd={true}
                  heading={<><span>trending in </span><span className="text-selected">{selectedLanguageName}</span></>}
                />

                <Grid container justifyContent={"center"}>
                  <MyAds type="banner_2" slotId={"8198258178"} height={100} mobtype={"mobile_1"} adv_type={"web_trending_fold_ad"} />
                </Grid>

                {opened ? <>
                  <LoadablePreferMood
                    refPage={"Trending"}
                    genres={
                      auth.allPreferences && auth.allPreferences.genres
                        ? auth.allPreferences.genres
                        : []
                    }
                    setSelectedGenre={setSelectedGenre}
                    selectedGenre={selectedGenre}
                    selectedGenreName={selectedGenreName}
                    setSelectedGenreName={setSelectedGenreName}
                    data={trending.genreMediaData ? trending.genreMediaData : []}
                    withAd={true}
                    heading={<><span>trending in </span><span className="text-selected"> {selectedGenreName}</span></>}
                  />

                  <LoadableCommonDetailTileListing
                    heading={
                      <>
                        <span className="text-selected">coming soon</span><span> on ott</span>
                      </>
                    }
                    subheading="get notified on release"
                    isShowHeading={true}
                    nextLink="/upcoming-movies-and-series-on-ott"
                    data={trending.comingSoonData}
                    showNotifyButton={true}
                    refPage="Coming Soon"
                    comingSoon={true}
                    hideAds={true}
                    withAd={true}
                  />

                  <Grid container justifyContent={"center"}>
                    <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"2945931490"} height={100} adv_type={"web_trending_fold_ad"} />
                  </Grid>
                </>
                  :
                  ''
                }
              </Grid>
            </Grid>

            {opened ? <>
              <Grid item xs={12} className="w-100 app-section">
                {trending.allWatchlistData &&
                  trending.allWatchlistData.length > 0 && (
                    <LoadableYourWatchlist data={trending.allWatchlistData} withAd={true} />
                  )}
              </Grid>
              {/* <Grid
                container
                className="app-container app-section pt-20"
                justifyContent={"center"}
              >
                <Grid item xs={12}>
                  <LoadableJoinWatchlist hideAds={true} heading={
                    <><span className="text-selected">join</span><span>the frenzi sharelist</span></>
                  } />
                </Grid>
              </Grid> */}

              <Grid container justifyContent={"center"}>
                <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"4133275680"} width={300} height={250} adv_type={"web_trending_fold_ad"} />
              </Grid>

              <Grid
                container
                className="app-container pt-0 pb-40"
                justifyContent={"center"}
              >
                <Grid item xs={12} className="app-section">
                  {trending.trendingInFriendsData &&
                    trending.trendingInFriendsData.length > 0 && (
                      <LoadableFriendsWatching
                        refPage={"Trending"}
                        data={trending.trendingInFriendsData}
                        withAd={true}
                        heading={<p className="m-0">
                          popular among <span className="text-selected">friends</span>{" "}
                        </p>}
                      />
                    )}

                  {trending.popularTodayData &&
                    trending.popularTodayData.length > 0 && (
                      <LoadableFrenziPopular
                        data={trending.popularTodayData}
                        isShowHeading={true}
                        withAd={true}
                        heading={<> <p className="m-0"> <span className="text-selected m-0">popular </span><span className="m-0"> on frenzi</span></p> </>}
                      />
                    )}

                  {trending.collectionMetaData &&
                    trending.collectionMetaData.length > 0 &&
                    <>
                      <Grid container justifyContent={"center"}>
                        <MyAds type="banner_2" mobtype={"mobile_1"} slotId={"8128361012"} width={250} height={250} adv_type={"web_trending_fold_ad"} />
                      </Grid>

                      {trending.collectionMetaData.map((x, i) => {
                        return (
                          <div key={i}>
                            <LoadableCollectionMedia data={x} is_free={false} withAd={true} />
                          </div>
                        );
                      })}
                    </>
                  }
                </Grid>

                <Grid container justifyContent={"center"}>
                  <MyAds type="banner_2" mobtype={"mobile_1"} adv_type={"web_trending_bottom_ad"} />
                </Grid>
              </Grid>
            </>
              :
              ''
            }
          </>
        </Grid>
        {!isMobileDevice() ?
          <Grid className="right-container" sx={{ display: { xs: 'none', md: 'block' } }}>
            <Grid className="right-container-ad">
              <MyAds type="banner_1" adv_type={'web_trending_top_ad'} />
            </Grid>
          </Grid>
          : ''}
      </Grid>
    </>
  );
}

export default Trending;
